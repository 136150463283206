import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/var/lib/jenkins/workspace/staging_botafy/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/var/lib/jenkins/workspace/staging_botafy/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/var/lib/jenkins/workspace/staging_botafy/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/staging_botafy/src/components/MainComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/staging_botafy/src/context/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DataProvider"] */ "/var/lib/jenkins/workspace/staging_botafy/src/context/dataContext.tsx");
